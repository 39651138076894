.page-footer {
    padding: 48px 0px 12px 0;
    background: var(--alt-blue);
    color: var(--alt-light-grey);  
    margin-top: 1rem;
}

.page-footer h6 {
    font-size: 1.6rem;
}

.page-footer p {
    color: var(--alt-light-grey);
}

.page-footer a {
    color: var(--alt-light-grey);
}

.page-footer ul {
    padding-left: 0;
}

.page-footer ul li {
    list-style-type: none;
}

.page-footer .btn {
    margin-right: 10px;
}

.page-footer .btn-primary {
    background-color: var(--alt-dark-grey);
    border-color: var(--alt-dark-grey);
    color: var(--alt-blue);
    font-family: var(--primary-font);
    padding: 0.8rem 1rem;
}

.page-footer .btn-outline-primary {
    border-color: var(--alt-dark-grey);
    color: var(--alt-dark-grey);
}
.page-footer .btn-outline-primary:hover {
    background-color: var(--alt-dark-grey);
    color: var(--alt-blue);
}