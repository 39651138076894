.page-header h1 {
    color: var(--alt-light-grey);
}

.page-header h2 {
    font-size: 1.4rem;
    font-family: var(--secondary-font);
    font-weight: 300;
    padding: 1rem 0;
}

.page-header h6 {
    font-size: 0.9rem;
    color: var(--alt-dark-grey);
}

.page-header .btn {
    margin-right: 10px;
}

.page-header .btn-primary {
    background-color: var(--alt-dark-grey);
    border-color: var(--alt-dark-grey);
    color: var(--alt-blue);
    font-family: var(--primary-font);
    padding: 0.8rem 1rem;
}
.page-header .btn-primary:hover {
    background-color: var(--alt-bronze);
    border-color: var(--alt-bronze);
    color: var(--alt-light-grey)
}

.page-header .btn-outline-primary {
    border-color: var(--alt-dark-grey);
    color: var(--alt-dark-grey);
    font-family: var(--primary-font);
    padding: 0.8rem 1rem;
}
.page-header .btn-outline-primary:hover {
    border-color: var(--alt-bronze);
    background-color: var(--alt-bronze);
    color: var(--alt-light-grey);
}
.page-header .btn-outline-primary:active {
    border-color: var(--alt-dark-grey);
    color: var(--alt-blue);
}