:root {
  --alt-blue: #2B2E4A;
  --alt-dark-grey: #9597A5;
  --alt-light-grey: #EAEAED;
  --alt-bronze: #4A472B;
  --alt-dark: #222222;

  --primary-font: 'Alata', sans-serif;
  --secondary-font: 'Libre Franklin', sans-serif;

  --bs-accordion-btn-color: var(var(--alt-blue));
}

html, body {
  min-height: 100%;
  height: 100%;
  font-family: var(--primary-font);
}

#root {
  height: 100%;
}

.App {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

main {
  flex-grow: 1;
}

footer {
  flex-shrink: 0;
}

.page-header {
  background-color: var(--alt-blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--alt-light-grey);
  padding: 4rem 0;
}


.navbar-brand img {
  height: 66px;
}
.navbar-light {
  background-color: var(--alt-light-grey);
}
.navbar-nav {
  gap: 1rem;
}
.navbar .btn-primary {
  background-color: var(--alt-bronze);
  border-color: var(--alt-bronze);
  color: var(--alt-light-grey);
  margin: 0 1rem;
}
.navbar-toggler {
  border: 0;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.nav-link {
  align-self: center;
}


p {
  font-family: var(--secondary-font);
  color: var(--alt-dark);
}

.main-content {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.summary-section {
  padding: 4rem 0;
}

.summary-section h2 {
  font-size: 1.6rem;
  color: var(--alt-blue);
}

.btn-primary {
  background-color: var(--alt-bronze);
  border-color: var(--alt-bronze);
  color: var(--alt-light-grey);
  font-family: var(--primary-font);
  padding: 0.8rem 1rem;
}
.btn-primary:hover {
  background-color: var(--alt-blue);
  border-color: var(--alt-blue);
}
.btn-primary:focus {
  background-color: var(--alt-dark-grey);
  border-color: var(--alt-dark-grey);
}
.btn-primary:active, .btn:first-child:active {
  background-color: var(--alt-blue);
  border-color: var(--alt-blue);
}

.btn-outline-primary {
  border-color: var(--alt-dark-grey);
  color: var(--alt-dark-grey);
}
.btn-outline-primary:hover {
  background-color: var(--alt-dark-grey);
  color: var(--alt-blue);
}


.profile h3 {
  color: var(--alt-blue);
  font-size: 1.3rem;
  font-family: var(--primary-font);
}

.profile  .qualification {
  color: var(--alt-dark-grey);
  font-size: 0.9rem;
}

.profile .job-title {
  color: var(--alt-dark-grey);
  font-family: var(--secondary-font);
}

.accordion-body {
  font-family: var(--secondary-font);
  color: var(--alt-dark);
}
.accordion-button:not(.collapsed) {
  background-color: var(--alt-light-grey);
  color: var(--alt-blue)
}
.accordion-button:focus{
  border-color: var(--alt-bronze);
  box-shadow: 0 0 0 0.1rem rgba(74, 71, 43, 0.25);
}

main.loading {
  opacity: 0.25;
  transition: opacity 200ms;
  transition-delay: 200ms;
}

@media (max-width: 575.98px) {  
  .list-group-flush>.list-group-item:last-child {
    border-bottom: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  }
}